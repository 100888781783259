// import DateFnsUtils from "@date-io/date-fns";
// import LuxonUtils from "@material-ui/pickers/adapter/luxon";
import LuxonUtils from "@date-io/luxon"
import { Checkbox, MenuItem, Select, TextField } from "@material-ui/core"
import { DatePicker, DateTimePicker, LocalizationProvider, TimePicker } from "@material-ui/pickers"
import PropTypes from "prop-types"
import * as React from "react"

const stylez = {
  fontSize: 13,
}
const dStyles = {
  style: stylez,
}
class MTableEditField extends React.Component<any, any> {
  public static propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    columnDef: PropTypes.object.isRequired,
  }

  public getProps() {
    const { columnDef, rowData, ...props } = this.props
    return props
  }

  public renderLookupField() {
    return (
      <Select
        {...this.getProps()}
        value={this.props.value === undefined ? "" : this.props.value}
        onChange={(event) => this.props.onChange(event.target.value)}
        style={stylez}
      >
        {Object.keys(this.props.columnDef.lookup).map((key) => (
          <MenuItem key={key} value={key}>
            {this.props.columnDef.lookup[key]}
          </MenuItem>
        ))}
      </Select>
    )
  }

  public renderBooleanField() {
    return (
      <Checkbox
        {...this.getProps()}
        value={String(this.props.value)}
        checked={Boolean(this.props.value)}
        onChange={(event) => this.props.onChange(event.target.checked)}
        style={{
          paddingLeft: 0,
          paddingTop: 0,
          paddingBottom: 0,
        }}
      />
    )
  }

  public renderDateField() {
    return (
      <LocalizationProvider dateAdapter={LuxonUtils}>
        <DatePicker
          {...this.getProps()}
          inputFormat="dd.MM.yyyy"
          value={this.props.value || null}
          onChange={this.props.onChange}
          clearable={true}
          InputProps={dStyles}
          renderInput={(props) => <TextField {...props} />}
        />
      </LocalizationProvider>
    )
  }

  public renderTimeField() {
    return (
      <LocalizationProvider dateAdapter={LuxonUtils}>
        <TimePicker
          {...this.getProps()}
          inputFormat="HH:mm:ss"
          value={this.props.value || null}
          onChange={this.props.onChange}
          clearable={true}
          renderInput={(props) => <TextField {...props} />}
          InputProps={dStyles}
        />
      </LocalizationProvider>
    )
  }

  public renderDateTimeField() {
    return (
      <LocalizationProvider dateAdapter={LuxonUtils}>
        <DateTimePicker
          {...this.getProps()}
          inputFormat="dd.MM.yyyy HH:mm:ss"
          value={this.props.value || null}
          onChange={this.props.onChange}
          clearable={true}
          renderInput={(props) => <TextField {...props} />}
          InputProps={dStyles}
        />
      </LocalizationProvider>
    )
  }

  public renderTextField() {
    return (
      <TextField
        {...this.getProps()}
        style={this.props.columnDef.type === "numeric" ? { float: "right" } : {}}
        type={this.props.columnDef.type === "numeric" ? "number" : "text"}
        placeholder={this.props.columnDef.title}
        value={this.props.value === undefined ? "" : this.props.value}
        onChange={(event) => this.props.onChange(event.target.value)}
        InputProps={{
          style: {
            fontSize: 13,
          },
        }}
      />
    )
  }

  public renderCurrencyField() {
    return "ok"
  }

  public render() {
    let component = "ok" as any

    if (this.props.columnDef.lookup) {
      component = this.renderLookupField()
    } else if (this.props.columnDef.type === "boolean") {
      component = this.renderBooleanField()
    } else if (this.props.columnDef.type === "date") {
      component = this.renderDateField()
    } else if (this.props.columnDef.type === "time") {
      component = this.renderTimeField()
    } else if (this.props.columnDef.type === "datetime") {
      component = this.renderDateTimeField()
    } else if (this.props.columnDef.type === "currency") {
      component = this.renderCurrencyField()
    } else {
      component = this.renderTextField()
    }

    return component
  }
}

export default MTableEditField
