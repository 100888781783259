import { CircularProgress, Paper, TablePagination } from "@material-ui/core"
import { fade } from "@material-ui/core/styles/colorManipulator"
// import Icon from '@material-ui/core/Icon';
import {
  Add,
  ArrowUpward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons"
import PropTypes from "prop-types"
import React from "react"
import * as MComponents from "./components"

const OverlayLoading = (props) => (
  <div style={{ display: "table", width: "100%", height: "100%", backgroundColor: fade(props.theme.palette.background.paper, 0.7) }}>
    <div style={{ display: "table-cell", width: "100%", height: "100%", verticalAlign: "middle", textAlign: "center" }}>
      <CircularProgress />
    </div>
  </div>
)
OverlayLoading.propTypes = {
  theme: PropTypes.any,
}

const renderIcon = (Icon: any) => React.forwardRef<any, any>((props: any, ref: any) => <Icon {...props} ref={ref} />)

export const defaultProps = {
  actions: [],
  classes: {},
  columns: [],
  components: {
    Action: MComponents.MTableAction,
    Actions: MComponents.MTableActions,
    Body: MComponents.MTableBody,
    Cell: MComponents.MTableCell,
    Container: Paper,
    EditField: MComponents.MTableEditField,
    EditRow: MComponents.MTableEditRow,
    FilterRow: MComponents.MTableFilterRow,
    Groupbar: MComponents.MTableGroupbar,
    GroupRow: MComponents.MTableGroupRow,
    Header: MComponents.MTableHeader,
    OverlayLoading,
    Pagination: TablePagination,
    Row: MComponents.MTableBodyRow,
    Toolbar: MComponents.MTableToolbar,
  },
  data: [],
  icons: {
    Add: renderIcon(Add),
    Check: renderIcon(Check),
    Clear: renderIcon(Clear),
    Delete: renderIcon(DeleteOutline),
    DetailPanel: renderIcon(ChevronRight),
    Edit: renderIcon(Edit),
    Export: renderIcon(SaveAlt),
    Filter: renderIcon(FilterList),
    FirstPage: renderIcon(FirstPage),
    LastPage: renderIcon(LastPage),
    NextPage: renderIcon(ChevronRight),
    PreviousPage: renderIcon(ChevronLeft),
    ResetSearch: renderIcon(Clear),
    Search: renderIcon(Search),
    SortArrow: renderIcon(ArrowUpward),
    ThirdStateCheck: renderIcon(Remove),
    ViewColumn: renderIcon(ViewColumn),
  },
  isLoading: false,
  title: "Table Title",
  options: {
    actionsColumnIndex: 0,
    addRowPosition: "last",
    columnsButton: false,
    detailPanelType: "multiple",
    debounceInterval: 200,
    doubleHorizontalScroll: false,
    emptyRowsWhenPaging: true,
    exportAllData: false,
    exportButton: false,
    exportDelimiter: ",",
    filtering: false,
    header: true,
    loadingType: "overlay",
    paging: true,
    pageSize: 5,
    pageSizeOptions: [5, 10, 20],
    paginationType: "normal",
    showEmptyDataSourceMessage: true,
    showSelectAllCheckbox: true,
    search: true,
    showTitle: true,
    toolbarButtonAlignment: "right",
    searchFieldAlignment: "right",
    searchFieldStyle: {},
    selection: false,
    sorting: true,
    toolbar: true,
    defaultExpanded: false,
    detailPanelColumnAlignment: "left",
  },
  localization: {
    grouping: {
      groupedBy: "Grouped By:",
      placeholder: "Drag headers here to group by",
    },
    pagination: {
      labelDisplayedRows: "{from}-{to} of {count}",
      labelRowsPerPage: "Rows per page:",
      labelRowsSelect: "rows",
    },
    toolbar: {},
    header: {},
    body: {
      filterRow: {},
      editRow: {
        saveTooltip: "Save",
        cancelTooltip: "Cancel",
        deleteText: "Are you sure delete this row?",
      },
      addTooltip: "Add",
      deleteTooltip: "Delete",
      editTooltip: "Edit",
    },
  },
}
