import Button, { ButtonProps } from "@material-ui/core/Button"
import Link, { LinkProps } from "@material-ui/core/Link"
import Typography, { TypographyProps } from "@material-ui/core/Typography"
import React from "react"
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom"

type Props = TypographyProps & RouterLinkProps

export const AdminLink: React.SFC<Props> = (props) => {
  const { variant, color, noWrap, className, ...linkProps } = props
  return (
    <Typography
      className={className}
      noWrap={!!noWrap}
      variant={variant || "button"}
      color={color || "inherit"}
      component={(passedProps) => <RouterLink {...(linkProps as any)} className={passedProps.className} />}
    />
  )
}

export const AdapterLink = React.forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref: any) => <RouterLink innerRef={ref} {...props} />)

export const ButtonLink = React.forwardRef<HTMLAnchorElement, RouterLinkProps & ButtonProps>((props, ref: any) => (
  <Button component={AdapterLink} {...(props as any)} ref={ref} />
))

// export const ButtonLink = (props: RouterLinkProps & ButtonProps) => (
//   <Button component={AdapterLink} {...props as any} />
// )
export const MuLink = (props: RouterLinkProps & LinkProps) => <Link component={AdapterLink} {...(props as any)} />

export default AdminLink
