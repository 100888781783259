import { TableCell } from "@material-ui/core"
import { DateTime } from "luxon"
import PropTypes from "prop-types"
import * as React from "react"

const createDt = (date: Date | string) => {
  const millis = date instanceof Date ? date.getTime() : Date.parse(date)
  return DateTime.fromMillis(millis)
}

export default class MTableCell extends React.Component<any, any> {
  public static defaultProps = {
    columnDef: {},
    value: undefined,
  }

  public static propTypes = {
    columnDef: PropTypes.object.isRequired,
    value: PropTypes.any,
    rowData: PropTypes.object,
  }

  public getRenderValue() {
    if (this.props.columnDef.emptyValue !== undefined && (this.props.value === undefined || this.props.value === null)) {
      return this.getEmptyValue(this.props.columnDef.emptyValue)
    }
    if (this.props.columnDef.render) {
      if (this.props.rowData) {
        return this.props.columnDef.render(this.props.rowData, "row")
      } else {
        return this.props.columnDef.render(this.props.value, "group")
      }
    } else if (this.props.columnDef.type === "boolean") {
      const style = { textAlign: "left", verticalAlign: "middle", width: 48 }
      if (this.props.value) {
        return <this.props.icons.Check style={style} />
      } else {
        return <this.props.icons.ThirdStateCheck style={style} />
      }
    } else if (this.props.columnDef.type === "date") {
      return createDt(this.props.value).toLocaleString(DateTime.DATE_MED as any)
    } else if (this.props.columnDef.type === "time") {
      return createDt(this.props.value).toLocaleString(DateTime.TIME_SIMPLE as any)
    } else if (this.props.columnDef.type === "datetime") {
      return createDt(this.props.value).toLocaleString(DateTime.DATETIME_MED as any)
    } else if (this.props.columnDef.type === "currency") {
      return this.getCurrencyValue(this.props.columnDef.currencySetting, this.props.value)
    }

    return this.props.value
  }

  public getEmptyValue(emptyValue) {
    if (typeof emptyValue === "function") {
      return this.props.columnDef.emptyValue(this.props.rowData)
    } else {
      return emptyValue
    }
  }

  public getCurrencyValue(currencySetting, value) {
    if (currencySetting !== undefined) {
      return new Intl.NumberFormat(currencySetting.locale !== undefined ? currencySetting.locale : "en-US", {
        style: "currency",
        currency: currencySetting.currencyCode !== undefined ? currencySetting.currencyCode : "USD",
        minimumFractionDigits: currencySetting.minimumFractionDigits !== undefined ? currencySetting.minimumFractionDigits : 2,
        maximumFractionDigits: currencySetting.maximumFractionDigits !== undefined ? currencySetting.maximumFractionDigits : 2,
      }).format(value !== undefined ? value : 0)
    } else {
      return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(value !== undefined ? value : 0)
    }
  }

  public handleClickCell = (e) => {
    if (this.props.columnDef.disableClick) {
      e.stopPropagation()
    }
  }

  public getStyle = () => {
    let cellStyle = {} as any

    if (typeof this.props.columnDef.cellStyle === "function") {
      cellStyle = { ...cellStyle, ...this.props.columnDef.cellStyle(this.props.value, this.props.rowData) }
    } else {
      cellStyle = { ...cellStyle, ...this.props.columnDef.cellStyle }
    }

    if (this.props.columnDef.disableClick) {
      cellStyle.cursor = "default"
    }
    cellStyle.whiteSpace = "nowrap"

    return { ...this.props.style, ...cellStyle }
  }

  public render() {
    const { icons, columnDef, rowData, ...cellProps } = this.props

    return (
      <TableCell
        {...cellProps}
        style={this.getStyle()}
        align={["numeric"].indexOf(this.props.columnDef.type) !== -1 ? "right" : "left"}
        onClick={this.handleClickCell}
      >
        {this.props.children}
        {this.getRenderValue()}
      </TableCell>
    )
  }
}
