import { Icon, IconButton, Tooltip } from "@material-ui/core"
import PropTypes from "prop-types"
import * as React from "react"

class MTableAction extends React.Component<any, any> {
  public static defaultProps = {
    action: {},
    data: {},
  }

  public static propTypes = {
    action: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  }

  public render() {
    let action = this.props.action
    if (typeof action === "function") {
      action = action(this.props.data)
      if (!action) {
        return null
      }
    }

    const handleOnClick = (event) => {
      if (action.onClick) {
        action.onClick(event, this.props.data)
        event.stopPropagation()
      }
    }

    const button = (
      <span>
        <IconButton color="secondary" disabled={action.disabled} onClick={(event) => handleOnClick(event)}>
          {typeof action.icon === "string" ? (
            <Icon {...action.iconProps} fontSize="small">
              {action.icon}
            </Icon>
          ) : (
            <action.icon {...action.iconProps} disabled={action.disabled} />
          )}
        </IconButton>
      </span>
    )

    if (action.tooltip) {
      return <Tooltip title={action.tooltip}>{button}</Tooltip>
    } else {
      return button
    }
  }
}

export default MTableAction
