import { useQuery } from "@apollo/client"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogActions from "@material-ui/core/DialogActions"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import { withStyles, WithStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import CloseIcon from "@material-ui/icons/Close"
import React, { ReactNode } from "react"
import { fromGlobalId } from "../../../common/global-ids"
import { emptyArray } from "../../../common/misc-utils"
import { AdminSQLQuery, AdminSQLQueryVariables } from "../../../__generated__/AdminSQLQuery"
import Code from "../../components/Code"
import LoadingView from "../components/LoadingView"
import { ADMIN_SQL_QUERY } from "../queries"

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute" as const,
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

interface Props extends WithStyles<typeof styles> {
  onClose?: (event: any) => any
  children?: ReactNode
  id?: string
}

const DialogTitle = withStyles(styles)((props: Props) => {
  const { children, classes, onClose } = props
  return (
    <MuiDialogTitle disableTypography={true} className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    minWidth: theme.spacing(50),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

interface IJsonBase {
  dialogTitle?: string
  buttonText?: string
  extraBtn?: any
}
interface IJsonColumn extends IJsonBase {
  data?: any
}
interface IDbElProps {
  tableName?: string
  id?: number
  globalId?: string
  fallbackData?: any
}
type IDbEl = IJsonBase & IDbElProps

const JsonDialog = ({ data }) => <Code>{JSON.stringify(data, null, 2)}</Code>

const ElDialog = (props: IDbElProps) => {
  let statement = ""
  try {
    const decoded = (props.globalId && fromGlobalId(props.globalId)) || undefined
    const tableName = props.tableName || decoded?.type
    const id = props.id || decoded?.id
    statement = `SELECT * FROM \`${tableName || decoded?.type}\` WHERE id = ${id}`
  } catch (error) {
    return <JsonDialog data={props.fallbackData || error} />
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const queryResult = useQuery<AdminSQLQuery, AdminSQLQueryVariables>(ADMIN_SQL_QUERY, { variables: { statement }, skip: !statement })
  if (queryResult.loading) {
    return <LoadingView />
  }
  const results = queryResult.data?.sql || emptyArray
  if (!queryResult.error && results.length === 0) {
    return <JsonDialog data={props.fallbackData || `No results found`} />
  }
  return <JsonDialog data={queryResult.error || results} />
}

const CustomizedDialogs = (props: IJsonColumn & IDbEl) => {
  const [open, setOpen] = React.useState(false)

  function handleClose() {
    setOpen(false)
  }

  function handleOpen() {
    setOpen(true)
  }
  const { buttonText, dialogTitle, data, fallbackData, tableName, id, extraBtn, globalId, ...rest } = props

  const isRemote = typeof data === "undefined"
  // console.log('props')
  // console.dir(props)
  return (
    <>
      <Button {...rest} variant={!isRemote ? "outlined" : "text"} color={!isRemote ? "secondary" : "primary"} onClick={handleOpen}>
        {isRemote ? buttonText : buttonText || "JSON"}
      </Button>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {dialogTitle || "Code"}
        </DialogTitle>
        <DialogContent dividers={true}>
          {!isRemote ? <JsonDialog data={data} /> : <ElDialog tableName={tableName} id={id} globalId={globalId} fallbackData={fallbackData} />}
        </DialogContent>
        <DialogActions>
          {(extraBtn && extraBtn()) || null}
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CustomizedDialogs
