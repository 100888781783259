import PropTypes from "prop-types"
import * as React from "react"

class MTableActions extends React.Component<any, any> {
  public static defaultProps = {
    actions: [],
    data: {},
  }

  public static propTypes = {
    components: PropTypes.object.isRequired,
    actions: PropTypes.array.isRequired,
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  }

  public render() {
    if (this.props.actions) {
      return this.props.actions.map((action, index) => (
        <this.props.components.Action action={action} key={"action-" + index} data={this.props.data} />
      ))
    }

    return null
  }
}

export default MTableActions
