import { withStyles, WithStyles } from "@material-ui/core"
import React from "react"
import { Query as TQuery } from "./index.d"
import MaterialTable from "./material-table"

const styles = (theme) => ({
  paginationRoot: {
    width: "100%",
  },
  paginationToolbar: {
    padding: 0,
    width: "100%",
  },
  paginationCaption: {
    display: "none",
  },
  paginationSelectRoot: {
    margin: 0,
  },
})

interface Props extends WithStyles<typeof styles> {
  tableRef?: any
  title?: string
  columns?: any
  options?: any
  actions?: any[]
  data?: (
    query: TQuery,
  ) => Promise<{
    data: any[]
    page: number
    totalCount: number
  }>
  editable?: {
    isEditable: () => boolean
    isDeletable: () => boolean
    onRowUpdate: (newData: any, oldData: any) => Promise<any>
  }
  initialQuery?: TQuery
}

export default withStyles(styles, { withTheme: true })(({ tableRef, ...rest }: Props) => <MaterialTable {...rest} ref={tableRef} />)
export * from "./components"
