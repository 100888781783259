/* eslint-disable no-unused-vars */
import { Button, Hidden, IconButton, Tooltip, withStyles } from "@material-ui/core"
import PropTypes from "prop-types"
import * as React from "react"
/* eslint-enable no-unused-vars */

class MTablePaginationInner extends React.Component<any, any> {
  public static propTypes = {
    onChangePage: PropTypes.func,
    page: PropTypes.number,
    count: PropTypes.number,
    rowsPerPage: PropTypes.number,
    classes: PropTypes.object,
    localization: PropTypes.object,
  }

  public static defaultProps = {
    localization: {
      previousTooltip: "Previous Page",
      nextTooltip: "Next Page",
      labelDisplayedRows: "{from}-{to} of {count}",
      labelRowsPerPage: "Rows per page:",
    },
  }

  public handleFirstPageButtonClick = (event) => {
    this.props.onChangePage(event, 0)
  }

  public handleBackButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page - 1)
  }

  public handleNextButtonClick = (event) => {
    this.props.onChangePage(event, this.props.page + 1)
  }

  public handleNumberButtonClick = (number) => (event) => {
    this.props.onChangePage(event, number)
  }

  public handleLastPageButtonClick = (event) => {
    this.props.onChangePage(event, Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1))
  }

  public renderPagesButton(start, end) {
    const buttons = [] as JSX.Element[]

    for (let p = start; p <= end; p++) {
      const buttonVariant = p === this.props.page ? "contained" : "text"
      const button = (
        <Button
          size="small"
          key={p + 1}
          style={{
            boxShadow: "none",
            maxWidth: "30px",
            maxHeight: "30px",
            minWidth: "30px",
            minHeight: "30px",
          }}
          disabled={p === this.props.page}
          variant={buttonVariant}
          onClick={this.handleNumberButtonClick(p)}
        >
          {p + 1}
        </Button>
      )
      buttons.push(button)
    }

    return <span>{buttons}</span>
  }

  public render() {
    const { classes, count, page, rowsPerPage } = this.props

    const localization = { ...MTablePaginationInner.defaultProps.localization, ...this.props.localization }
    const maxPages = Math.ceil(count / rowsPerPage) - 1

    const pageStart = Math.max(page - 1, 0)
    const pageEnd = Math.min(maxPages, page + 1)

    return (
      <div className={classes.root}>
        <Tooltip title={localization.previousTooltip}>
          <span>
            <IconButton onClick={this.handleBackButtonClick} disabled={page === 0} aria-label={localization.previousAriaLabel}>
              <this.props.icons.PreviousPage />
            </IconButton>
          </span>
        </Tooltip>
        <Hidden smDown={true}>{this.renderPagesButton(pageStart, pageEnd)}</Hidden>
        <Tooltip title={localization.nextTooltip}>
          <span>
            <IconButton onClick={this.handleNextButtonClick} disabled={page >= maxPages} aria-label={localization.nextAriaLabel}>
              <this.props.icons.NextPage />
            </IconButton>
          </span>
        </Tooltip>
      </div>
    )
  }
}

const actionsStyles = (theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
})

const MTablePagination = withStyles(actionsStyles, { withTheme: true })(MTablePaginationInner)

export default MTablePagination
