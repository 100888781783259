// import DateFnsUtils from "@date-io/date-fns";
// import LuxonUtils from "@material-ui/pickers/adapter/luxon";
import LuxonUtils from "@date-io/luxon"
import {
  Checkbox,
  FormControl,
  Input,
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from "@material-ui/core"
import { FilterList } from "@material-ui/icons"
import { DatePicker, DateTimePicker, LocalizationProvider, TimePicker } from "@material-ui/pickers"
import { DateTime } from "luxon"
import PropTypes from "prop-types"
import * as React from "react"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}
const Filter = React.forwardRef((props: any, ref) => {
  // console.log('Filter')
  // console.dir(props)
  const { Component, ...rest } = props
  // <props.icons.Filter />
  return <FilterList {...rest} ref={ref} />
})

class MTableFilterRow extends React.Component<any, any> {
  public static defaultProps = {
    emptyCell: false,
    columns: [],
    selection: false,
    hasActions: false,
    localization: {
      filterTooltip: "Filter",
    },
  }

  public static propTypes = {
    emptyCell: PropTypes.bool,
    columns: PropTypes.array.isRequired,
    hasDetailPanel: PropTypes.bool.isRequired,
    isTreeData: PropTypes.bool.isRequired,
    onFilterChanged: PropTypes.func.isRequired,
    filterCellStyle: PropTypes.object,
    selection: PropTypes.bool.isRequired,
    onFilterSelectionChanged: PropTypes.func.isRequired,
    actionsColumnIndex: PropTypes.number,
    hasActions: PropTypes.bool,
    localization: PropTypes.object,
  }

  public renderLookupFilter = (columnDef) => (
    <FormControl style={{ width: "100%" }}>
      <Select
        multiple={true}
        value={columnDef.tableData.filterValue || []}
        onChange={(event) => {
          this.props.onFilterChanged(columnDef.tableData.id, event.target.value)
        }}
        input={<Input id="select-multiple-checkbox" />}
        renderValue={(selecteds) => (selecteds as any[]).map((selected) => columnDef.lookup[selected]).join(", ") as string}
        MenuProps={MenuProps}
      >
        {Object.keys(columnDef.lookup).map((key) => (
          <MenuItem key={key} value={key}>
            <Checkbox checked={columnDef.tableData.filterValue ? columnDef.tableData.filterValue.indexOf(key.toString()) > -1 : false} />
            <ListItemText primary={columnDef.lookup[key]} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )

  public renderBooleanFilter = (columnDef) => (
    <Checkbox
      indeterminate={columnDef.tableData.filterValue === undefined}
      checked={columnDef.tableData.filterValue === "checked"}
      onChange={() => {
        let val
        if (columnDef.tableData.filterValue === undefined) {
          val = "checked"
        } else if (columnDef.tableData.filterValue === "checked") {
          val = "unchecked"
        }

        this.props.onFilterChanged(columnDef.tableData.id, val)
      }}
    />
  )

  public renderDefaultFilter = (columnDef) => {
    const localization = { ...MTableFilterRow.defaultProps.localization, ...this.props.localization }
    return (
      <TextField
        style={columnDef.type === "numeric" ? { float: "right" } : {}}
        type={columnDef.type === "numeric" ? "number" : "text"}
        value={columnDef.tableData.filterValue || ""}
        onChange={(event) => {
          this.props.onFilterChanged(columnDef.tableData.id, event.target.value)
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Tooltip title={localization.filterTooltip}>
                <Filter />
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
    )
  }

  public buildDateFilterChange = (id: string | number) => (date: any | null) => {
    this.props.onFilterChanged(id, date as DateTime | null)
  }

  public renderDateTypeFilter = (columnDef) => {
    const val = columnDef.tableData.filterValue || new Date()

    const dateInputElement =
      (columnDef.type === "date" && (
        <DatePicker
          value={val}
          onChange={this.buildDateFilterChange(columnDef.tableData.id)}
          clearable={true}
          renderInput={(props) => <TextField {...props} />}
        />
      )) ||
      (columnDef.type === "datetime" && (
        <DateTimePicker
          value={val}
          onChange={this.buildDateFilterChange(columnDef.tableData.id)}
          clearable={true}
          renderInput={(props) => <TextField {...props} />}
        />
      )) ||
      (columnDef.type === "time" && (
        <TimePicker
          value={val}
          onChange={this.buildDateFilterChange(columnDef.tableData.id)}
          clearable={true}
          renderInput={(props) => <TextField {...props} />}
        />
      )) ||
      null

    return <LocalizationProvider dateAdapter={LuxonUtils}>{dateInputElement}</LocalizationProvider>
  }

  public getComponentForColumn(columnDef) {
    if (columnDef.filtering === false) {
      return null
    }

    if (columnDef.field || columnDef.customFilterAndSearch) {
      if (columnDef.lookup) {
        return this.renderLookupFilter(columnDef)
      } else if (columnDef.type === "boolean") {
        return this.renderBooleanFilter(columnDef)
      } else if (["date", "datetime", "time"].includes(columnDef.type)) {
        return this.renderDateTypeFilter(columnDef)
      } else {
        return this.renderDefaultFilter(columnDef)
      }
    }
    return null
  }

  public render() {
    const columns = this.props.columns
      .filter((columnDef) => !columnDef.hidden && !(columnDef.tableData.groupOrder > -1))
      .sort((a, b) => a.tableData.columnOrder - b.tableData.columnOrder)
      .map((columnDef) => (
        <TableCell key={columnDef.tableData.id} style={{ ...this.props.filterCellStyle, ...columnDef.filterCellStyle }}>
          {this.getComponentForColumn(columnDef)}
        </TableCell>
      ))

    if (this.props.selection) {
      columns.splice(
        0,
        0,
        <TableCell padding="none" key="key-selection-column">
          <Checkbox onChange={this.props.onFilterSelectionChanged} />
        </TableCell>,
      )
    }
    if (this.props.emptyCell && this.props.hasActions) {
      if (this.props.actionsColumnIndex === -1) {
        columns.push(<TableCell key="key-action-column" />)
      } else {
        let endPos = 0
        if (this.props.selection) {
          endPos = 1
        }
        columns.splice(this.props.actionsColumnIndex + endPos, 0, <TableCell key="key-action-column" />)
      }
    }

    if (this.props.hasDetailPanel) {
      columns.splice(0, 0, <TableCell padding="none" key="key-detail-panel-column" />)
    }

    if (this.props.isTreeData > 0) {
      const _col = <TableCell padding="none" key={"key-tree-data-filter"} />
      columns.splice(0, 0, _col)
    }

    this.props.columns
      .filter((columnDef) => columnDef.tableData.groupOrder > -1)
      .forEach((columnDef) => {
        columns.splice(0, 0, <TableCell padding="checkbox" key={"key-group-filter" + columnDef.tableData.id} />)
      })

    return <TableRow style={{ height: 10 }}>{columns}</TableRow>
  }
}

export default MTableFilterRow
